import React from "react";
import { Link } from "gatsby";

const navStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  textAlign: "center",
};
const linkStyles = {
  fontSize: "2rem",
  textDecoration: "none",
  color: "#000",
  borderBottom: "3px solid #dcd932",
  textTransform: "uppercase",
  letterSpacing: "2.5px",
  fontWeight: "bold",
};

export default function Layout({ children }) {
  return (
    <div>
      {children}
      <nav style={navStyles}>
        <ul style={{ listStyle: `none`, padding: 0 }}>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/abound">
              Abound
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/aventure">
              Aventure
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/level">
              Level
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/pace-350">
              Pace 350
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/pace-500">
              Pace 500
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/sinch">
              Sinch
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/sinch-st">
              Sinch ST
            </Link>
          </li>
          <li style={{ margin: `1rem 0` }}>
            <Link style={linkStyles} to="/soltera">
              Soltera
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
