import * as React from "react";
import Layout from "../components/layout"
import "../styles/global-styles.css";


const IndexPage = () => {
  return (
    <Layout></Layout>
  );
};

export default IndexPage;
